import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Box from 'reusecore/src/elements/Box';
import Button from 'reusecore/src/elements/Button';
import Text from 'reusecore/src/elements/Text';
import Image from 'reusecore/src/elements/Image';
import Heading from 'reusecore/src/elements/Heading';
import FeatureBlock from 'common/src/components/FeatureBlock';
import Container from 'common/src/components/UI/Container';
import BannerWrapper, {
  BannerObject,
  ExternalLink,
} from './bannerSection.style';

import NotebooksPrivateImage from 'common/src/assets/image/saas/notebooks_private.png';

const NotebooksPrivateSection = ({
  row,
  col,
  title,
  description,
  btnStyle,
}) => {
  const ButtonGroup = () => (
    <Fragment>
      <a href="https://demo.europanb.online/" target="_blank">
        <Button title="DEMO" {...btnStyle} />
      </a>
    </Fragment>
  );
  return (
    <BannerWrapper id="banner_section">
      <Container>
        <Box className="row" {...row}>
          <Box className="col" {...col}>
            <FeatureBlock
              title={<Heading content="Europa Notebooks Private" {...title} />}
              description={
                <div>
                  <Text
                    content="Europa Notebooks Private is installed into your cloud tenancy or on-premises data centre."
                    {...description}
                  />
                  <Text />
                  <Text
                    content="It is integrated with your specific data platform and may be customized to your needs."
                    {...description}
                  />
                  <Text
                    content={
                      <span>
                        For more information contact us at{' '}
                        <ExternalLink href="mailto:enquiry@europa-labs.com">
                          enquiry(at)europa-labs.com
                        </ExternalLink>
                        .
                      </span>
                    }
                    {...description}
                  />
                </div>
              }
              button={<ButtonGroup />}
            />
          </Box>
        </Box>
      </Container>
      <BannerObject>
        <div className="objectWrapper">
          <Image src={NotebooksPrivateImage} alt="Melbourne City" />
        </div>
      </BannerObject>
    </BannerWrapper>
  );
};

NotebooksPrivateSection.propTypes = {
  title: PropTypes.object,
  btnStyle: PropTypes.object,
  description: PropTypes.object,
  contentStyle: PropTypes.object,
  discountText: PropTypes.object,
  discountAmount: PropTypes.object,
  outlineBtnStyle: PropTypes.object,
};

NotebooksPrivateSection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
    alignItems: 'center',
  },
  col: {
    pr: '15px',
    pl: '15px',
    width: [1, '70%', '50%', '45%'],
  },
  title: {
    fontSize: ['22px', '34px', '30px', '40px'],
    fontWeight: '700',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: ['20px', '25px'],
    lineHeight: '1.5',
  },
  description: {
    fontSize: '16px',
    color: '#343d48cc',
    lineHeight: '1.75',
    mb: '0',
  },
  btnStyle: {
    type: 'button',
    fontSize: '14px',
    fontWeight: '600',
    borderRadius: '4px',
    pl: '22px',
    pr: '22px',
    colors: 'primaryWithBg',
    height: '46px',
  },
};

export default NotebooksPrivateSection;
